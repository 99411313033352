@import 'public/styles/colors';
@import 'public/styles/fonts';
@import 'public/styles/css3-mixins';

.GradientButton {
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    background-color: $gray;
    font-size: $text-semi-normal;
    margin: 0 10px;
    cursor: pointer;
    transition: background .3s ease-in-out;
    min-width: 130px;
    @each $color in $colors {
        &.#{map-get($color, color)} {
            @include background-horizontal(map-get($color, gradient), map-get($color, normal));
        }
    }
    &:focus,
    &:active {
        outline: none;
    }
    &.disabled {
        pointer-events: none;
        background: $gray;
    }
}