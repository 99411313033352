.HexagStar {
    position: relative;
    background-image: url(../../../public/images/svg/blue-hexagon.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    &.medium {
        .star-container {
            padding-top: 3px;
        }
    }
    &.difficult {
        .star-container {
            padding-top: 1px;
        }
    }
}