@import "public/styles/colors";
@import "public/styles/css3-mixins";

.SearchBar {
    background-color: $mid-blue;
    display: flex;
    align-items: baseline;
    padding: 8px 10px;
    border-radius: 5px;
    transition: box-shadow .3s ease-in-out;
    input {
        background-color: transparent;
        border: none;
        color: white;
        margin: 0 5px;
        &:focus,
        &:active {
            outline: none;
        }
    }
    &:focus,
    &:active,
    &.focused {
        @include box-shadow(0, 0, 0, 2px, $green);
    }
}
