.thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    
    &:hover {
        transform: scale(1.05);
    }
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px 5px 0 0;
    }
    
    .thumbnail__info {
        padding: 1rem;
        text-align: center;
    
        h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        }
    
        p {
        font-size: 1rem;
        color: #777;
        }
    }
}