@import "public/styles/colors";

.SingleExercise {
    box-sizing: border-box;
    padding: 40px;
    .row {
        display: flex;
        margin-bottom: 40px;
        .container {
            margin: 0 20px;
            .label {
                margin: 5px 0;
            }
            .help-description {
                color: $gray;
            }
            .items-container {
                display: flex;
                flex-wrap: wrap;
                &.media {
                    align-items: center;
                }
            }
            &.body-area {
                flex-basis: 40%;
            }
            &.muscles {
                flex-basis: 60%;
                flex-shrink: 0;
            }
            &.multimedia {
                .add-file-container {
                    cursor: pointer;
                    margin-left: 15px;
                    #add-file {
                        width: 100%;
                        height: 100%;
                        min-height: 100%;
                        position: absolute;
                        top: 0;
                        cursor: pointer;
                        left: 0;
                        // opacity: 0;
                        &::-webkit-file-upload-button {
                            visibility: hidden;
                        }
                    }
                }
            }
        }
        .toggle-home-container {
            display: flex;
            align-items: center;
            margin: 10px 0;
            p {
                margin: 0 10px;
            }
        }
    }
    .btns-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
