@import 'public/styles/colors';
@import 'public/styles/fonts';

.Pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 40px;
    .pagination-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .paginator {
        margin: 0;
        cursor: pointer;
        padding: 20px;
        &:hover {
          svg {
            fill: $green;
          }
        }
        .icon-container {
            &.left {
                transform-origin: center center;
                transform: rotate(180deg);
            }
        }
      }
      .pages {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .page {
          padding: 5px;
          cursor: pointer;
          transition: color 0.3s ease-in-out;
          color: $gray;
          font-family: $montserrat;
          &:hover {
            color: $green;
          }
          &.current {
              color: $green;
          }
        }
      }
    }
  }
  