@import 'public/styles/colors';

.Exercises {
    .top-filters {
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid white;
    }
}