@import 'public/styles/colors';

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn .3s ease-in-out forwards;
    z-index: 2000;
    .modal-inner {
        background-color: $mid-blue;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 5px;
        max-width: 70%;
        opacity: 0;
        transform: translateY(20px);
        animation: comeIn .3s .3s ease-in-out forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes comeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}