/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */


////
/// @author Matthieu Aussaguel
/// @group sass-css3-mixins
////


/// Adds a browser prefix to the property
/// @param {*} $property Property
/// @param {*} $value Value

@mixin css3-prefix($property, $value) {
    -webkit-#{$property}: #{$value};
     -khtml-#{$property}: #{$value};
       -moz-#{$property}: #{$value};
        -ms-#{$property}: #{$value};
         -o-#{$property}: #{$value};
            #{$property}: #{$value};
  }
  
  
  /// Background Gradient
  /// @param {Color} $startColor [#3C3C3C] - Start Color
  /// @param {Color} $endColor [#999999] - End Color
  
  @mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
      background-color: $startColor;
      background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
      background-image: -webkit-linear-gradient($startColor, $endColor);
      background-image:    -moz-linear-gradient($startColor, $endColor);
      background-image:     -ms-linear-gradient($startColor, $endColor);
      background-image:      -o-linear-gradient($startColor, $endColor);
      background-image:         linear-gradient($startColor, $endColor);
      filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
  }
  
  
  /// Background Horizontal
  /// @param {Color} $startColor [#3C3C3C] - Start Color
  /// @param {Color} $endColor [#999999] - End Color
  
  @mixin background-horizontal($startColor: #3C3C3C, $endColor: #999999) {
      background-color: $startColor;
      background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
      background-image: -webkit-linear-gradient(left, $startColor, $endColor);
      background-image:    -moz-linear-gradient(left, $startColor, $endColor);
      background-image:     -ms-linear-gradient(left, $startColor, $endColor);
      background-image:      -o-linear-gradient(left, $startColor, $endColor);
      background-image:         linear-gradient(to right, $startColor, $endColor);
      filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
  }
  
  
  /// Background Horizontal - inverse direction
  /// @param {Color} $startColor [#999999] - Start Color
  /// @param {Color} $endColor [#3C3C3C] - End Color
  
  @mixin background-horizontal-inverse($startColor: #999999, $endColor: #3C3C3C) {
      background-color: $startColor;
      background-image: -webkit-gradient(linear, right top, left top, from($startColor), to($endColor));
      background-image: -webkit-linear-gradient(right, $startColor, $endColor);
      background-image:    -moz-linear-gradient(right, $startColor, $endColor);
      background-image:     -ms-linear-gradient(right, $startColor, $endColor);
      background-image:      -o-linear-gradient(right, $startColor, $endColor);
      background-image:         linear-gradient(to left, $startColor, $endColor);
      filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$endColor}', endColorStr='#{$startColor}', gradientType='1');
  }
  
  
  /// Background Radial
  /// @param {Color} $startColor [#3C3C3C] - Start Color
  /// @param {Percentage} $startPos [0%] - Start position
  /// @param {Color} $endColor [#999999] - End Color
  /// @param {Percentage} $endPos [100%] - End position
  
  @mixin background-radial($startColor: #FFFFFF, $startPos: 0%, $endColor: #000000, $endPos:100%) {
      background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
      background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop($startPos,$startColor), color-stop($endPos,$endColor));
      background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
      background: -o-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
      background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
      background: radial-gradient(ellipse at center, $startColor $startPos,$endColor $endPos);
  }
  
  
  /// Background Size
  /// @param {Size} $width [100%] - Width
  /// @param {Size} $width [$width] - Height
  /// @require {mixin} css3-prefix
  
  @mixin background-size($width: 100%, $height: $width) {
    @if type-of($width) == 'number' and $height != null {
      @include css3-prefix('background-size', $width $height);
    } @else {
      @include css3-prefix('background-size', $width);
    }
  }
  
  
  /// Background Color Opacity
  /// @param {Color} $color [100%] - Color
  /// @param {Double} $opacity [0.85] - Opacity
  
  @mixin background-opacity($color: #000, $opacity: 0.85) {
    background: $color;
    background: rgba($color, $opacity);
  }
  
  
  /// Border Radius
  /// @param {Size} $radius [5px] - Radius
  /// @require {mixin} css3-prefix
  
  @mixin border-radius($radius: 5px) {
      @include css3-prefix('border-radius', $radius);
  }
  
  
  /// Border Radius Separate
  /// @param {Size} $topLeftRadius [5px] - Top Left
  /// @param {Size} $topRightRadius [5px] - Top Right
  /// @param {Size} $bottomLeftRadius [5px] - Bottom Left
  /// @param {Size} $bottomRightRadius [5px] - Bottom Right
  
  @mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
    -webkit-border-top-left-radius:     $topLeftRadius;
    -webkit-border-top-right-radius:    $topRightRadius;
    -webkit-border-bottom-right-radius: $bottomRightRadius;
    -webkit-border-bottom-left-radius:  $bottomLeftRadius;
  
    -moz-border-radius-topleft:     $topLeftRadius;
    -moz-border-radius-topright:    $topRightRadius;
    -moz-border-radius-bottomright: $bottomRightRadius;
    -moz-border-radius-bottomleft:  $bottomLeftRadius;
  
    border-top-left-radius:     $topLeftRadius;
    border-top-right-radius:    $topRightRadius;
    border-bottom-right-radius: $bottomRightRadius;
    border-bottom-left-radius:  $bottomLeftRadius;
  }
  
  
  /// Box
  /// @param {*} $orient [horizontal] - Orientation
  /// @param {*} $pack [center] - Pack
  /// @param {*} $align [center] - Align
  /// @require {mixin} css3-prefix
  
  @mixin box($orient: horizontal, $pack: center, $align: center) {
    display: -webkit-box;
    display: -moz-box;
    display: box;
  
    @include css3-prefix('box-orient', $orient);
    @include css3-prefix('box-pack', $pack);
    @include css3-prefix('box-align', $align);
  }
  
  
  /// Box RGBA
  /// @param {Integer} $r [60] - Red
  /// @param {Integer} $g [3] - Green
  /// @param {Integer} $b [12] - Blue
  /// @param {Double} $opacity [0.23] - Opacity
  /// @param {Color} $color [#3C3C3C] - Color
  
  @mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
    background-color: transparent;
    background-color: rgba($r, $g, $b, $opacity);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
              zoom:   1;
  }
  
  
  /// Box Shadow
  /// @param {Size} $x [0px] - X
  /// @param {Size} $y [5px] - Y
  /// @param {Size} $blur [10px] - Blur
  /// @param {Color} $color [rgba(0,0,0,.1)] - Color
  /// @param {Boolean} $inset - Inset
  
  @mixin box-shadow($x: 0px, $y: 5px, $blur: 10px, $color: rgba(0,0,0,.1), $inset: "") {
    @if ($inset != "") {
      @include css3-prefix('box-shadow', $inset $x $y $blur $color);
    } @else {
      @include css3-prefix('box-shadow', $x $y $blur $color);
    }
  }
  
  /// Box Shadow Colored
  /// @param {Color} $color [rgba(0,0,0,.1)] - Color
  /// @param {Boolean} $inset - Inset
  
  @mixin box-shadow-color($color: rgba(0,0,0,.1), $inset: "") {
    @if ($inset != "") {
      @include css3-prefix('box-shadow', $inset 0px 5px 10px rgba($color, 0.2));
    } @else {
      @include css3-prefix('box-shadow', 0px 5px 10px rgba($color, 0.2));
    }
  }
  
  
  /// Box Sizing
  /// @param {*} $type [border-box] - Type
  /// @require {mixin} css3-prefix
  
  @mixin box-sizing($type: border-box) {
    @include css3-prefix('box-sizing', $type);
  }
  
  
  /// Columns
  /// @param {Integer} $count [3] - Count
  /// @param {Integer} $gap [10] - Gap
  /// @require {mixin} css3-prefix
  
  @mixin columns($count: 3, $gap: 10) {
    @include css3-prefix('column-count', $count);
    @include css3-prefix('column-gap', $gap);
  }
  
  
  /// Double Borders
  /// @param {Color} $colorOne [#3C3C3C] - Color One
  /// @param {Color} $colorTwo [#999999] - Color Two
  /// @param {Size} $radius [0] - Radius
  /// @require {mixin} css3-prefix
  /// @require {mixin} border-radius
  
  @mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
    border: 1px solid $colorOne;
  
    @include css3-prefix('box-shadow', 0 0 0 1px $colorTwo);
  
    @include border-radius( $radius );
  }
  
  
  /// Flex
  /// @param {Integer} $value [1] - Value
  /// @require {mixin} css3-prefix
  
  @mixin flex($value: 1) {
    @include css3-prefix('box-flex', $value);
  }
  
  
  /// Flip
  /// @param {Double} $scaleX [-1] - ScaleX
  /// @require {mixin} css3-prefix
  
  @mixin flip($scaleX: -1) {
    @include css3-prefix('transform', scaleX($scaleX));
    filter:            FlipH;
    -ms-filter:        "FlipH";
  }
  
  
  /// Font Face
  /// @param {Font} $fontFamily [myFont] - Font Family
  /// @param {String} $eotFileSrc ['myFont.eot'] - Eot File Source
  /// @param {String} $woffFileSrc ['myFont.woff'] - Woff File Source
  /// @param {String} $ttfFileSrc ['myFont.ttf'] - Ttf File Source
  /// @param {String} $svgFileSrc ['myFont.svg'] - Svg File Source
  
  @mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf', $svgFileSrc: 'myFont.svg', $svgFontID: '#myFont') {
    font-family: $fontFamily;
    src: url($eotFileSrc)  format('eot'),
         url($woffFileSrc) format('woff'),
         url($ttfFileSrc)  format('truetype'),
         url($svgFileSrc + $svgFontID) format('svg');
  }
  
  
  /// Opacity
  /// @param {Double} $opacity [0.5] - Opacity
  /// @require {mixin} css3-prefix
  
  @mixin opacity($opacity: 0.5) {
      $opacityMultiplied: ($opacity * 100);
  
      filter:         alpha(opacity=$opacityMultiplied);
      -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
      @include css3-prefix('opacity', $opacity);
  }
  
  
  /// Outline Radius
  /// @param {Size} $radius [5px] - Radius
  /// @require {mixin} css3-prefix
  
  @mixin outline-radius($radius: 5px) {
    @include css3-prefix('outline-radius', $radius);
  }
  
  
  /// Resize
  /// @param {*} $directoin [both] - Direction
  /// @require {mixin} css3-prefix
  
  @mixin resize($direction: both) {
    @include css3-prefix('resize', $direction);
  }
  
  
  /// Rotate
  ///
  /// CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
  /// @param {Double} $deg [0] - Degree
  /// @param {Double} $m11 [0] - M11
  /// @param {Double} $m12 [0] - M12
  /// @param {Double} $m21 [0] - M21
  /// @param {Double} $m22 [0] - M22
  /// @require {mixin} css3-prefix
  
  @mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
    @include css3-prefix('transform', rotate($deg + deg));
    filter: progid:DXImageTransform.Microsoft.Matrix(
         M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
      zoom: 1;
  }
  
  
  /// Text Shadow
  /// @param {Size} $x [2px] - X
  /// @param {Size} $y [2px] - Y
  /// @param {Size} $blur [2px] - Blur
  /// @param {Color} $color [rgba(0,0,0,.4)] - Color
  
  @mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
      text-shadow: $x $y $blur $color;
  }
  
  
  /// Transform
  /// @param {List} $params - Params
  /// @require {mixin} css3-prefix
  
  @mixin transform($params) {
    @include css3-prefix('transform', $params);
  }
  
  
  /// Transform-Origin
  /// @param {List} $params - Params
  /// @require {mixin} css3-prefix
  
  @mixin transform-origin($params) {
    @include css3-prefix('transform-origin', $params);
  }
  
  
  // Transform-Style
  /// @param {List} $params - Params
  /// @require {mixin} css3-prefix
  
  @mixin transform-style($style: preserve-3d) {
    @include css3-prefix('transform-style', $style);
  }
  
  /// Transition
  /// @param {List} $properties - Properties
  /// @require {mixin} css3-prefix
  
  @mixin transition($properties...) {
  
    @if length($properties) >= 1 {
      @include css3-prefix('transition', $properties);
    }
  
    @else {
      @include css3-prefix('transition',  "all 0.2s ease-in-out 0s");
    }
  }
  
  
  /// Triple Borders
  /// @param {Color} $colorOne [#3C3C3C] - Color One
  /// @param {Color} $colorTwo [#999999] - Color Two
  /// @param {Color} $colorThree [#000000] - Color Three
  /// @param {Size} $radius [0] - Radius
  /// @require {mixin} border-radius
  /// @require {mixin} css3-prefix
  
  @mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
      border: 1px solid $colorOne;
  
      @include border-radius($radius);
  
      @include css3-prefix('box-shadow', "0 0 0 1px #{$colorTwo}, 0 0 0 2px #{$colorThree}");
  }
  
  
  /// Keyframes
  /// @param {*} $animation-name - Animation name
  /// @content [Animation css]
  
  @mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }
    @-ms-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
      @content;
    }
    @keyframes #{$animation-name} {
      @content;
    }
  }
  
  
  /// Animation
  /// @param {*} $str - name duration timing-function delay iteration-count direction fill-mode play-state ([http://www.w3schools.com/cssref/css3_pr_animation.asp](http://www.w3schools.com/cssref/css3_pr_animation.asp))
  /// @require {mixin} css3-prefix
  
  @mixin animation($str) {
    @include css3-prefix('animation', $str);
  }
  