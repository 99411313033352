@import "public/styles/colors";

.video-buttons-container {
    display: flex;
    align-items: center;

    button {
        background: transparent;
        border: 1px solid $green;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 0.5em 1em;
        cursor: pointer;
    }
}

.video-preview-container {
    max-height: 60vh;
    margin-bottom: 1em;
    border-radius: 5px;
    overflow: hidden;

    video {
        width: 100%;
        height: 100%;
    }
}