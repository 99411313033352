@import 'public/styles/colors';

.Checkbox {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 2000px;
    width: 15px;
    height: 15px;
    border: 1px solid white;
    transition: background-color .3s ease-in-out;
    cursor: pointer;
    &:checked {
        background-color: $green;
    }
    &:active,
    &:focus {
        outline: none;
    }
}