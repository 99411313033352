@import "public/styles/colors";
@import "public/styles/fonts";

.Sidebar {
    width: 100%;
    background-color: $mid-blue;
    height: 100vh;
    box-sizing: border-box;
    padding: 20px 0;
    header {
        margin-bottom: 50px;
        .logo-container {
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            .title {
                font-size: $text-large;
                color: white;
                margin: 0 10px;
            }
        }
    }
    .list-menu {
        li {
            width: 100%;
            .menu-item-link {
                box-sizing: border-box;
                padding: 0 30px;
                width: 100%;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                transition: background-color 0.3s ease-in-out;
                .left-side {
                    display: flex;
                    align-items: center;
                    .icon-container {
                        margin-right: 10px;
                    }
                    p {
                        color: white;
                        transition: color 0.3s ease-in-out;
                    }
                }
                &:hover {
                    background-color: $dark-blue;
                }
                &.selected {
                    .left-side {
                        p {
                            color: $green;
                        }
                    }
                }
            }
        }
    }
    .logout {
        box-sizing: border-box;
        padding: 0 30px;
        width: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        .left-side {
            display: flex;
            align-items: center;
            .icon-container {
                margin-right: 10px;
            }
            p {
                color: white;
                transition: color 0.3s ease-in-out;
            }
        }
        &:hover {
            background-color: $dark-blue;
        }
        &.selected {
            .left-side {
                p {
                    color: $green;
                }
            }
        }
    }
}

.spacer {
    width: 300px;
    flex-shrink: 0;
}
