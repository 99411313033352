.Hexagon {
    position: relative;
    margin: auto;
    cursor: pointer;
    .content {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
}