.MediaPreview {
    max-width: 200px;
    margin: 20px 10px;
    p {
        margin: 0;
        text-align: center;
    }
    .img-preview-container {
        border-radius: 5px;
        overflow: hidden;
        img {
            margin-bottom: -5px;
        }
    }
}