@import 'public/styles/colors';

.DifficultySelector {
    display: flex;
    justify-content: space-around;
    .level {
        margin: 0 10px;
        cursor: pointer;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        transition: background-color .3s ease-in-out;
        &:hover,
        &.selected {
            background-color: $mid-blue;
        }
    }
}