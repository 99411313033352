.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .field {
        margin: 10px;
        position: relative;
        .toggle-psw {
            position: absolute;
            top: 2px;
            right: 5px;
            cursor: pointer;
        }
    }
}