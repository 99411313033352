@import 'public/styles/colors';
@import 'public/styles/fonts';
@import 'public/styles/css3-mixins';

.NewButton {
    border: none;
    border-radius: 5px;
    background-color: $mid-blue;
    padding: 5px 50px;
    height: 32px;
    font-size: $text-normal;
    cursor: pointer;
    position: relative;
    margin-left: 30px;
    transition: box-shadow .3s ease-in-out;
    &:hover {
        @include box-shadow(0, 0, 0, 1px, $green);
    }
    &:active,
    &:focus {
        outline: none;
        @include box-shadow(0, 0, 0, 1px, $green);
    }
    .hexagon-container {
        position: absolute;
        left: -30px;
        top: -15px;
        width: 60px;
        height: 60px;
        background-image: url(../../../public/images/hexagons/white-green-lines.svg);
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark-blue;
        font-size: $text-medium;
    }
}