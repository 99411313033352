@import 'public/styles/comove';

.App {
    display: flex;
    .panel-content {
        background-color: $dark-blue;
        min-height: 100vh;
        width: 100%;
        .toasts-container {
            position: fixed;
            top: 0;
            right: 0;
            width: 200px;
        }
    }
}