@import './colors';

a {
    color: unset;
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

img {
    width: 100%;
    height: auto;
}

svg {
    path {
        transition: fill .3s ease-in-out;
    }
}

::selection {
    background-color: $light-blue;
}

.content-table {
    width: 100%;
    border-collapse: collapse;
    thead {
        tr {
            td {
                padding: 0 10px;
            }
        }
    }
    tbody {
        tr {
            cursor: pointer;
            &:nth-of-type(odd) {
                background-color: $mid-blue;
            }
            td {
                padding: 10px;
            }
        }
    }
}

.delete-modal {
    .btns-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}