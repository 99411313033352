@import "public/styles/colors";
@import "public/styles/css3-mixins";

.BodyPart {
    background-color: $mid-blue;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    padding-right: 10px;
    min-width: 200px;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    transition: box-shadow .3s ease-in-out;
    margin-bottom: 10px;
    .img-container {
        width: 80px;
        img {
            margin-bottom: -5px;
        }
    }
    &:hover,
    &.selected {
        @include box-shadow(0, 0, 0, 2px, $green);
    }
}