@import 'public/styles/colors';
@import 'public/styles/css3-mixins';

.filter-card {
    margin: 10px 0;
    border-radius: 5px;
    background-color: $mid-blue;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: box-shadow .3s ease-in-out;
    p {
        margin: 0;
    }
    .icon-container {
        margin-right: 10px;
        width: 25px;
    }
    &.selected,
    &:hover {
        @include box-shadow(0, 0, 0, 2px, $green);
    }
}