$light-blue: #5ACAE2;
$mid-blue: #323C46;
$dark-blue: #262F38;
$green: #B7E25A;
$white: #FFFFFF;
$gray: #CACACA;
$black: #000000;
$red: #FF7171;
$orange: #FFCA41;
// Buttons gradients
$light-deg-green: #B6E25A;
$dark-deg-green: #7EE25A;
$light-deg-blue: #5AE2DA;
$dark-deg-blue: #5ACAE2;

$colors: (
    //                            normal: normal/dark version          gradient: light version
    (color: 'light-blue',         normal: $light-blue,                 gradient: $light-deg-blue),
    (color: 'mid-blue',           normal: $mid-blue,                   gradient: $mid-blue),
    (color: 'dark-blue',          normal: $dark-blue,                  gradient: $dark-blue),
    (color: 'green',              normal: $green,                      gradient: $green),
    (color: 'white',              normal: $white,                      gradient: $white),
    (color: 'gray',               normal: $gray,                       gradient: $gray),
    (color: 'black',              normal: $black,                      gradient: $black),
    (color: 'red',                normal: $red,                        gradient: $red),
    (color: 'orange',             normal: $orange,                     gradient: $orange),
    (color: 'deg-green',          normal: $dark-deg-green,             gradient: $light-deg-green),
);

@function color($color, $type: normal) {
    @return map-get(map-get($colors, $color), $type);
}
