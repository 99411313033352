@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

$montserrat: 'Montserrat', sans-serif;

h1, h2, h3, h4, h5, h6, p, li, a, input, textarea, button {
    font-family: $montserrat;
    color: white;
}

strong,
.bold {
    font-weight: 700;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

// Font Sizes
$text-small: 10px;
$text-normal: 14px;
$text-semi-normal: 18px;
$text-medium: 24px;
$text-large: 36px;

.text-small {
    font-size: $text-small;
}

.text-normal {
    font-size: $text-normal;
}

.text-semi-normal {
    font-size: $text-semi-normal;
}

.text-medium {
    font-size: $text-medium;
}

.text-large {
    font-size: $text-large;
}