@import 'public/styles/colors';
@import 'public/styles/fonts';
@import 'public/styles/css3-mixins';

.InputText {
    background-color: $mid-blue;
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    font-size: $text-semi-normal;
    transition: box-shadow .3s ease-in-out;
    &:focus,
    &:active {
        outline: none;
        @include box-shadow(0, 0, 0, 1px, $green);
    }
    &.dark {
        background-color: $dark-blue;
    }
}

textarea {
    min-width: 350px;
}