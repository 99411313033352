@import "public/styles/colors";
@import "public/styles/css3-mixins";

.Card {
    width: 100px;
    overflow: hidden;
    border-radius: 5px;
    background-color: $mid-blue;
    position: relative;
    margin: 10px;
    cursor: pointer;
    transition: box-shadow .3s ease-in-out;
    .img-container {
        height: 100px;
        img {
            height: 100%;
            min-width: 100%;
            object-fit: cover;
        }
    }
    .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            margin: 5px 0;
            text-align: center;
        }
    }
    .top-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($green, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        .check-container {
            width: 40px;
        }
    }
    &.selected {
        .top-layer {
            opacity: 1;
        }
    }
    &:hover {
        @include box-shadow(0, 0, 0, 2px, $green);
    }
}
