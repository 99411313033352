@import 'public/styles/colors';

.ShinyHexagon {
    position: relative;
    .children-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}